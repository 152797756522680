import { TextField, TextFieldProps, InputAdornment } from '@mui/material';
import { IconButton } from '../IconButton';
import styled from '@emotion/styled';

import { IconCancel } from '@core/icons';
import { ChangeEvent, MouseEvent, ReactNode, useCallback } from 'react';
export type InputTextProps = TextFieldProps & {
  endAdornment?: ReactNode;
  useClear?: boolean;
};
const ClearButton = styled(IconButton)`
  justify-content: center;
  display: flex;
  &.MuiInputBase-root:focus {
    width: 30px;
  }
`;
export const InputText = ({
  endAdornment,
  InputProps,
  useClear = true,
  ...props
}: InputTextProps) => {
  const handleClear = useCallback(() => {
    if (props?.onChange) {
      props?.onChange({
        target: { name: props?.name ?? '', value: '' },
      } as ChangeEvent<HTMLInputElement>);
    }
  }, [props]);

  return (
    <TextField
      {...props}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="start" sx={{ marginLeft: 0 }}>
            {useClear ? (
              <ClearButton
                className="clear-button"
                onMouseDown={handleClear}
                disabled={!props?.value}
              >
                <IconCancel />
              </ClearButton>
            ) : null}
            {endAdornment}
          </InputAdornment>
        ),
      }}
    />
  );
};

import { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';

import { TestQuestions } from '@modules/meta';
import { ScoreUnitTypeCode } from '@core/constants/enum';

import { RadioScoreInfoCol, RadioWidth } from '../ScoreUnits';
import {
  Col,
  ColWrap,
  MeasurementRows,
  MeasurementRowsProps,
  RowContainer,
  ScoreInfoCol,
} from './MeasurementRows';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { audioSelector, scoreRecordsSelector } from '@src/recoils/test';
import { ScoreRecordInfos } from '@src/modules/patientTest';

export interface TableProps extends Omit<MeasurementRowsProps, 'header'> {
  screenShare: boolean;
  questions?: TestQuestions['questionInfo'];
  scoreLength?: number;
  contentLength?: number;
}

const RadioColWidth = 20;

const TableContainer = styled.div`
  width: 100%;
  display: table;
  border-top: ${({ theme }) => `1px solid ${theme.color.grey[200]}`};
`;

type HeaderType = {
  label?: string;
  id: string;
  children?: HeaderType[];
  style?: any;
};

export const Table = ({
  screenShare,
  measurements = [],
  questions = [],
  questionId = 0,
  categoryCode,
  isDetail,
  scoreLength = 0,
  contentLength = 0,
}: TableProps) => {
  const setScoreRecord = useSetRecoilState(
    scoreRecordsSelector({ categoryCode, questionId }),
  );

  const hasObservation = measurements?.some(({ observation }) => observation);
  const pointScoreInfo = useMemo(
    () =>
      measurements
        ?.find(({ scoreInfo }) => scoreInfo?.unitType?.code === ScoreUnitTypeCode.POINT)
        ?.scoreInfo?.scoreDescription?.scores?.sort(
          (a, b) => Number(a.key) - Number(b.key),
        ),
    [measurements],
  );

  const header: HeaderType[] = useMemo(
    () => [
      { label: 'No.', style: { width: '30px' }, id: 'rowNum' },
      {
        label: '평가요인',
        id: 'label',
        style: { width: hasObservation ? '20%' : 'auto' },
      },
      ...(screenShare
        ? [{ label: '화면공유', style: { width: '60px' }, id: 'screenShare' }]
        : []),
      ...(hasObservation ? [{ label: '관찰점', id: 'observation' }] : []),
      pointScoreInfo
        ? {
            children: pointScoreInfo
              ? pointScoreInfo.map(({ key }) => ({
                  label: key,
                  id: `scoreInfo-point-${key}`,
                }))
              : [],
            id: 'scoreInfo',
          }
        : {
            label: '평가',
            id: 'scoreInfo',
            style: {
              width: `calc(${
                (scoreLength === 0 ? 3 : scoreLength) * RadioWidth
              }px - 32px)`,
            },
          },
      { label: '특이사항', style: { width: '88px' }, id: 'comment' },
    ],
    [pointScoreInfo, screenShare, hasObservation, scoreLength],
  );

  const audioInfo = useRecoilValue(audioSelector(categoryCode));

  useEffect(() => {
    if (audioInfo?.audio) {
      const timeUnit = measurements?.find(
        (measurement) => measurement?.scoreInfo?.unitType?.code === 'TIME',
      );
      const countUnit = measurements?.find(
        (measurement) => measurement?.scoreInfo?.unitType?.code === 'COUNT',
      );
      const speedUnit = measurements?.find(
        (measurement) => measurement?.scoreInfo?.unitType?.code === 'SPEED',
      );
      const newRecords: ScoreRecordInfos[] = [];

      if (timeUnit) {
        newRecords.push({
          measurementMappingId: timeUnit?.measurementMappingId,
          score: audioInfo?.duration?.toString() ?? '',
        });
      }
      if (countUnit) {
        newRecords.push({
          measurementMappingId: countUnit?.measurementMappingId,
          score: audioInfo?.regions?.toString(),
        });
      }

      if (speedUnit) {
        newRecords.push({
          measurementMappingId: speedUnit?.measurementMappingId,
          score: (
            contentLength / Number(((audioInfo?.duration ?? 0) / 1000)?.toFixed(1))
          )?.toFixed(1),
        });
      }

      if (newRecords?.length) {
        setScoreRecord(newRecords);
      }
    }
  }, [audioInfo.audio]);

  return (
    <TableContainer>
      <RowContainer type="th">
        {header?.map(({ style, label, children = [] }: HeaderType, index) => {
          return children?.length ? (
            <ScoreInfoCol
              style={{ width: `${RadioColWidth * children?.length}px` }}
              key={index}
            >
              <ColWrap>
                {children?.map(({ label }: HeaderType, childIndex) => (
                  <RadioScoreInfoCol key={childIndex}>{label}</RadioScoreInfoCol>
                ))}
              </ColWrap>
            </ScoreInfoCol>
          ) : (
            <Col key={index} style={style}>
              <ColWrap>{label}</ColWrap>
            </Col>
          );
        })}
      </RowContainer>
      {questions?.length ? (
        questions?.map(({ content, questionId }, index) => {
          return (
            <MeasurementRows
              key={index}
              categoryCode={categoryCode}
              measurements={measurements}
              no={index}
              name={content}
              header={header?.map(({ id }) => id)}
              questionId={questionId}
              isDetail={isDetail}
            />
          );
        })
      ) : (
        <MeasurementRows
          categoryCode={categoryCode}
          measurements={measurements}
          header={header?.map(({ id }) => id)}
          questionId={questionId}
          isDetail={isDetail}
        />
      )}
    </TableContainer>
  );
};

import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { ApiError } from '@core/types/api';
import { QueryOptions } from '@core/types/react-query';
import { TEST_KEY } from '../key';

export const useGetAudio = (
  url: string,
  options?: QueryOptions<Blob, AxiosError<ApiError>>,
) => {
  return useQuery<Blob, AxiosError<ApiError>, Blob>({
    ...options,
    queryKey: [TEST_KEY, url],
    queryFn: async () => {
      const { data } = await axios.get(url, {
        responseType: 'blob',
      });
      return data;
    },
  });
};

import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import styled from '@emotion/styled';

import { scoreRecordInfosSelector } from '@recoils/test';
import { ScoreInfo } from '@modules/meta';
import { ScoreUnitTypeCode } from '@core/constants/enum';

import PercentSlider from '@components/PercentSlider/PercentSlider';
import { InputNumber } from '@components/InputNumber';
import { InputText } from '@components/InputText';
import { Radio } from '@components/Radio';
import { Select } from '@src/components/Select';
import { Box, Stack } from '@mui/material';

export const RadioWidth = 60;
export const RadioScoreInfoCol = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${RadioWidth}px;
  :not(:last-of-type) {
    border-right: ${({ theme }) => `1px solid ${theme.color.grey[200]}`};
  }
`;

const ChoiceLabel = styled.label`
  color: ${({ theme }) => theme.color.grey[900]};
  font-weight: 400;
  font-size: 16px;
  margin-right: 8px;
`;

export interface ScoreUnitsProps {
  scoreInfo: ScoreInfo;
  questionId: number;
  measurementMappingId: number;
  categoryCode: string;
  isDetail?: boolean;
  pointLength?: number;
}
export const ScoreUnits = ({
  scoreInfo,
  measurementMappingId,
  questionId,
  categoryCode,
  isDetail,
  pointLength,
}: ScoreUnitsProps) => {
  const [scoreRecord, setScoreRecord] = useRecoilState(
    scoreRecordInfosSelector({ categoryCode, questionId, measurementMappingId }),
  );

  const handleChangeScoreUnits = useCallback(
    (value: string) => {
      setScoreRecord({ score: value });
    },
    [setScoreRecord],
  );

  const scoreInfoTypeCode = scoreInfo?.unitType?.code;

  return (
    <>
      {scoreInfoTypeCode === ScoreUnitTypeCode.POINT ? (
        scoreInfo?.scoreDescription?.scores?.length === pointLength ? (
          scoreInfo?.scoreDescription?.scores
            ?.sort((a, b) => Number(a.key) - Number(b.key))
            .map(({ key }) => (
              <RadioScoreInfoCol key={key}>
                <Radio
                  name={`${measurementMappingId}`}
                  value={key}
                  checked={scoreRecord?.score === key}
                  onChange={({ target: { value } }) => handleChangeScoreUnits(value)}
                  disabled={isDetail}
                />
              </RadioScoreInfoCol>
            ))
        ) : (
          <Select
            key={scoreRecord?.score}
            options={scoreInfo?.scoreDescription?.scores?.map((score) => ({
              value: score?.key,
              label: score?.value ?? score?.key,
            }))}
            value={scoreRecord?.score}
            onChange={(value) => {
              handleChangeScoreUnits(value);
            }}
            fullWidth
            sx={{ margin: 1 }}
          />
        )
      ) : scoreInfoTypeCode === ScoreUnitTypeCode.PERCENT ? (
        <PercentSlider
          step={5}
          value={scoreRecord?.score ? Number(scoreRecord?.score) : 0}
          onChange={(event, value) => handleChangeScoreUnits(value.toString())}
          disabled={isDetail}
        />
      ) : scoreInfoTypeCode === ScoreUnitTypeCode.TEXT ? (
        <InputText
          sx={{ width: '100%' }}
          value={scoreRecord?.score}
          onChange={({ target: { value } }) => handleChangeScoreUnits(value)}
          disabled={isDetail}
        />
      ) : scoreInfoTypeCode === ScoreUnitTypeCode.CHOICE ? (
        <>
          <RadioScoreInfoCol onClick={() => handleChangeScoreUnits('2')}>
            <ChoiceLabel htmlFor={'Rt'}>Rt.</ChoiceLabel>
            <Radio
              id={'Rt'}
              name={`${measurementMappingId}`}
              value={'2'}
              checked={scoreRecord?.score === '2'}
              onChange={({ target: { value } }) => handleChangeScoreUnits(value)}
              disabled={isDetail}
            />
          </RadioScoreInfoCol>
          <RadioScoreInfoCol onClick={() => handleChangeScoreUnits('1 ')}>
            <ChoiceLabel htmlFor={'Lt'}>Lt.</ChoiceLabel>
            <Radio
              id={'Lt'}
              name={`${measurementMappingId}`}
              value={'1'}
              checked={scoreRecord?.score === '1'}
              onChange={({ target: { value } }) => handleChangeScoreUnits(value)}
              disabled={isDetail}
            />
          </RadioScoreInfoCol>
        </>
      ) : scoreInfoTypeCode === ScoreUnitTypeCode.CHOICE2 ? (
        <>
          <RadioScoreInfoCol onClick={() => handleChangeScoreUnits('2')}>
            <ChoiceLabel htmlFor={'Rt'}>Rt.</ChoiceLabel>
            <Radio
              id={'Rt'}
              name={`${measurementMappingId}`}
              value={'2'}
              checked={scoreRecord?.score === '2'}
              onChange={({ target: { value } }) => handleChangeScoreUnits(value)}
              disabled={isDetail}
            />
          </RadioScoreInfoCol>
          <RadioScoreInfoCol onClick={() => handleChangeScoreUnits('1')}>
            <ChoiceLabel htmlFor={'Lt'}>Lt.</ChoiceLabel>
            <Radio
              id={'Lt'}
              name={`${measurementMappingId}`}
              value={'1'}
              checked={scoreRecord?.score === '1'}
              onChange={({ target: { value } }) => handleChangeScoreUnits(value)}
              disabled={isDetail}
            />
          </RadioScoreInfoCol>
          <RadioScoreInfoCol onClick={() => handleChangeScoreUnits('3')}>
            <ChoiceLabel htmlFor={'Bi'}>Bi.</ChoiceLabel>
            <Radio
              id={'Bi'}
              name={`${measurementMappingId}`}
              value={'3'}
              checked={scoreRecord?.score === '3'}
              onChange={({ target: { value } }) => handleChangeScoreUnits(value)}
              disabled={isDetail}
            />
          </RadioScoreInfoCol>
        </>
      ) : (
        <Stack flexDirection="row" gap={1} width="100%" alignItems="center">
          <InputNumber
            value={scoreRecord?.score}
            fullWidth
            onChange={({ target: { value } }) => handleChangeScoreUnits(value)}
            disabled={isDetail}
          />
          {scoreInfo?.unitType?.unit && (
            <Box sx={{ whiteSpace: 'nowrap' }}>{scoreInfo?.unitType?.unit}</Box>
          )}
        </Stack>
      )}
    </>
  );
};

import { useCallback, ChangeEvent } from 'react';
import { InputText, InputTextProps } from '../InputText';
import styled from '@emotion/styled';

export type InputNumberProps = InputTextProps & {
  max?: number;
  min?: number;
};

const Input = styled(InputText)`
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    display: none;
  }
`;
export const InputNumber = ({
  max,
  min,
  value = '',
  onChange,
  ...props
}: InputNumberProps) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      if (!/^\d+$/.test(value)) {
        return;
      }
      if (max && max < Number(value)) {
        event.target.value = max.toString();
      }

      if (value !== '' && min !== undefined && min > Number(value)) {
        event.target.value = min.toString();
      }
      if (onChange) {
        onChange(event);
      }
    },
    [max, min, onChange],
  );

  return <Input {...props} type={'number'} value={value} onChange={handleChange} />;
};

import styled from '@emotion/styled';
import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record';
import RegionsPlugin, { Region } from 'wavesurfer.js/dist/plugins/regions';
import { IconButton } from '../IconButton';
import { IconRecording, IconPause, IconPlay, IconPlaying } from '@core/icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { testSelector } from '@src/recoils/test';
import { useGetAudio } from '@src/modules/patientTest';
import PercentSlider from '../PercentSlider/PercentSlider';
import { Box, Stack, Typography } from '@mui/material';
import { Timer } from '../Timer';

const Wrap = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.color.grey[50]};
  height: 52px;
  border-radius: 88px;
  padding: 16px 40px;
  align-items: center;
`;

const ControlWrap = styled.div`
  min-width: 184px;
  gap: 16px;
  display: flex;
  margin-right: 16px;
`;

const Button = styled(IconButton)`
  padding: 0;
  width: 32px;
  height: 32px;
`;

const WaveContainer = styled.div`
  width: 100%;
`;

export const Recorder = ({
  categoryCode,
  isDetail,
}: {
  categoryCode: string;
  isDetail?: boolean;
}) => {
  const waveRef = useRef<HTMLDivElement>(null);
  const waveSurferRef = useRef<WaveSurfer | null>(null);
  const recordRef = useRef<RecordPlugin | null>(null);
  const regionsRef = useRef<RegionsPlugin>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRecordingPaused, setIsRecordingPaused] = useState(false);
  const [minValue, setMinValue] = useState(0.01);
  const [minSilenceDuration, setMinSilenceDuration] = useState(0.01);
  const [mergeDuration, setMergeDuration] = useState(0.02);
  const [timeCount, setTimeCount] = useState(0);

  const [test, setTest] = useRecoilState(testSelector(categoryCode));
  const isRecording = recordRef.current && recordRef.current.isRecording();
  const { data: audio } = useGetAudio(test?.voiceRecordingUrl ?? '', {
    enabled: !!test?.voiceRecordingUrl,
  });

  const extractRegions = useCallback(
    (audioData: Float32Array, duration: number) => {
      const scale = duration / audioData.length;
      const silentRegions = [];

      // Find all silent regions longer than minSilenceDuration
      let start = 0;
      let end = 0;
      let isSilent = false;
      for (let i = 0; i < audioData.length + 10; i++) {
        if (audioData[i] < minValue) {
          if (!isSilent) {
            start = i;
            isSilent = true;
          }
        } else if (isSilent) {
          end = i;
          isSilent = false;
          if (scale * (end - start) > minSilenceDuration) {
            silentRegions.push({
              start: scale * start,
              end: scale * end,
            });
          }
        }
      }

      // Merge silent regions that are close together
      const mergedRegions = [];
      let lastRegion = null;
      for (let i = 0; i < silentRegions.length; i++) {
        if (lastRegion && silentRegions[i].start - lastRegion.end < mergeDuration) {
          lastRegion.end = silentRegions[i].end;
        } else {
          lastRegion = silentRegions[i];
          mergedRegions.push(lastRegion);
        }
      }

      // const mergedRegions = [];
      // let lastRegionEnd = null;
      // for (let i = 0; i < silentRegions.length; i++) {
      //   if (
      //     lastRegionEnd !== null &&
      //     silentRegions[i].start - lastRegionEnd < mergeDuration
      //   ) {
      //     mergedRegions[mergedRegions.length - 1].end = silentRegions[i].end;
      //   } else {
      //     lastRegionEnd = silentRegions[i].end;
      //     mergedRegions.push({ ...silentRegions[i] });
      //   }

      //   if (
      //     i === silentRegions?.length - 1 &&
      //     mergedRegions[mergedRegions?.length - 1]?.end !== silentRegions[i].end
      //   ) {
      //     mergedRegions[mergedRegions.length - 1].end = silentRegions[i].end;
      //   }
      // Find regions that are not silent
      const regions = [];
      let lastEnd = 0;
      for (let i = 0; i < mergedRegions.length; i++) {
        regions.push({
          start: lastEnd,
          end: mergedRegions[i].start,
        });
        lastEnd = mergedRegions[i].end;
      }

      return regions;
    },
    [minSilenceDuration, mergeDuration, minValue],
  );

  useEffect(() => {
    if (waveRef.current) {
      waveSurferRef.current = WaveSurfer.create({
        progressColor: '#5C7CFA',
        waveColor: '#FA5252',
        barHeight: 2,
        cursorWidth: 0,
        height: 56,
        container: waveRef.current,
      });

      regionsRef.current = waveSurferRef.current.registerPlugin(RegionsPlugin.create());

      recordRef.current = waveSurferRef.current.registerPlugin(RecordPlugin.create());

      recordRef.current.on('record-progress', (time) => {
        setTimeCount(Number((time / 10)?.toFixed()));
        if (categoryCode.startsWith('001:001:003') && time > 5100) {
          recordRef?.current?.stopRecording();
        }
      });

      let durationLoaded = false;
      if (recordRef.current) {
        recordRef.current;
        recordRef.current.on('record-end', setAudioFile);
      }
      if (waveSurferRef.current) {
        waveSurferRef.current.on('ready', () => {
          if (
            !durationLoaded &&
            waveSurferRef.current &&
            !isRecording &&
            waveSurferRef.current.getMediaElement().src
          ) {
            durationLoaded = true;
            const duration = waveSurferRef.current.getDuration();
            setTimeCount(Math.round(duration * 100));
          }
        });

        waveSurferRef.current.on('finish', () => {
          setIsPlaying(false);
        });

        let activeRegion: Region | null = null;
        if (regionsRef.current) {
          regionsRef.current.on('region-clicked', (region, e) => {
            e.stopPropagation();
            region.play();
            activeRegion = region;
          });
        }

        waveSurferRef.current.on('timeupdate', (currentTime) => {
          // When the end of the region is reached
          if (activeRegion && currentTime >= activeRegion.end) {
            // Stop playing
            if (waveSurferRef.current) {
              waveSurferRef.current.pause();
              activeRegion = null;
            }
          }
        });
      }
    }

    return () => {
      if (waveSurferRef.current) {
        waveSurferRef.current.destroy();
        waveSurferRef.current = null;
      }
      if (recordRef.current) {
        recordRef?.current.destroy();
        recordRef.current = null;
      }
    };
  }, []);

  const setAudioFile = useCallback(
    async (blob: Blob) => {
      if (waveSurferRef.current) {
        waveSurferRef.current.on('decode', (duration) => {
          // Create regions for each non-silent part of the audio
          if (regionsRef.current && waveSurferRef.current) {
            regionsRef.current.clearRegions();
            const decodedData = waveSurferRef.current.getDecodedData();
            if (decodedData && !recordRef.current?.isRecording()) {
              const regions = extractRegions(decodedData.getChannelData(0), duration);

              if (blob) {
                setTimeCount(Number((decodedData?.duration * 100)?.toFixed()));
                setTest({
                  audio: blob,
                  testCategoryCode: categoryCode,
                  duration: decodedData?.duration,
                  regions: regions?.length - 1,
                });
              }
              if (recordRef.current) {
                regions.forEach((region, index) => {
                  if (regionsRef.current) {
                    regionsRef.current.addRegion({
                      start: region.start,
                      end: region.end,
                      content: index.toString(),
                      drag: false,
                      resize: false,
                    });
                  }
                });
              }
            }
          }
        });
      }

      setIsRecordingPaused(false);
    },
    [setTest, extractRegions, categoryCode],
  );

  const handleRecordStop = useCallback(() => {
    if (recordRef?.current) {
      recordRef.current.stopRecording();
    }
  }, []);

  const handleRecordPause = useCallback(() => {
    if (recordRef.current) {
      if (!recordRef.current.isPaused()) {
        recordRef.current.pauseRecording();
        setIsRecordingPaused(true);
      }
    }
  }, [setIsRecordingPaused]);

  const handleRecord = useCallback(() => {
    if (recordRef.current) {
      if (recordRef.current.isPaused()) {
        recordRef.current.resumeRecording();
        setIsRecordingPaused(false);
        return;
      }
      recordRef.current?.startRecording().then(() => {
        setIsPlaying(false);
      });
    }
  }, []);

  const handlePlay = useCallback(() => {
    if (waveSurferRef.current) {
      waveSurferRef.current.playPause();
      setIsPlaying((prev) => !prev);
    }
  }, [setIsPlaying]);

  const handleChangeMinValue = useCallback(({ target: { value } }: any) => {
    setMinValue(value);
  }, []);
  const handleChangeMergeDuration = useCallback(({ target: { value } }: any) => {
    setMergeDuration(value);
  }, []);
  const handleChangeMinSilenceDuration = useCallback(({ target: { value } }: any) => {
    setMinSilenceDuration(value);
  }, []);

  // const setThreshold = useCallback(() => {
  //   if (waveSurferRef.current && regionsRef.current) {
  //     regionsRef.current.clearRegions();
  //     const decodedData = waveSurferRef.current.getDecodedData();
  //     if (decodedData && !recordRef.current?.isRecording()) {
  //       const regions = extractRegions(
  //         decodedData.getChannelData(0),
  //         waveSurferRef?.current?.getDuration(),
  //       );
  //       // Add regions to the waveform
  //       if (recordRef.current) {
  //         regions.forEach((region, index) => {
  //           if (regionsRef.current) {
  //             regionsRef.current.addRegion({
  //               start: region.start,
  //               end: region.end,
  //               content: index.toString(),
  //               drag: false,
  //               resize: false,
  //             });
  //           }
  //         });
  //       }
  //     }
  //   }
  // }, [minValue, mergeDuration, extractRegions, minSilenceDuration]);

  // useEffect(() => {
  //   setThreshold();
  // }, [setThreshold]);

  useEffect(() => {
    if (waveSurferRef?.current?.getMediaElement()?.src) {
      return;
    }
    const audioSource = test?.audio || audio;
    if (audioSource) {
      const recordedUrl = URL.createObjectURL(audioSource);
      if (waveSurferRef.current) {
        waveSurferRef.current.load(recordedUrl);
      }
    }
  }, [audio, test?.audio]);

  return (
    <Box mt={2} mb={3}>
      <Wrap>
        {isDetail && !audio ? (
          '녹음된 음성파일이 없습니다.'
        ) : (
          <>
            <ControlWrap>
              {!isDetail && (
                <>
                  <Button onClick={isRecording ? handleRecordPause : handleRecord}>
                    {isRecording ? <IconPlaying /> : <IconRecording />}
                  </Button>
                  {isRecording || isRecordingPaused ? (
                    <Button onClick={handleRecordStop}>
                      <IconPause />
                    </Button>
                  ) : null}
                </>
              )}

              {!(isRecording || isRecordingPaused) && (audio || test?.audio) ? (
                <Button onClick={handlePlay}>
                  {isPlaying ? <IconPlaying /> : <IconPlay />}
                </Button>
              ) : null}
              <Timer count={timeCount ?? 0} />
            </ControlWrap>
            <WaveContainer ref={waveRef}></WaveContainer>
          </>
        )}
      </Wrap>
      {!isDetail && (
        <Stack spacing={1}>
          <Stack direction="row" spacing={2}>
            <Typography gutterBottom>minValue</Typography>
            <PercentSlider
              valueLabelFormat={undefined}
              min={0.01}
              max={0.05}
              step={0.001}
              value={minValue}
              onChange={handleChangeMinValue}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography gutterBottom>minSilenceDuration</Typography>
            <PercentSlider
              valueLabelFormat={undefined}
              min={0.01}
              max={0.05}
              step={0.001}
              onChange={handleChangeMinSilenceDuration}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography gutterBottom>mergeDuration</Typography>
            <PercentSlider
              valueLabelFormat={undefined}
              min={0.01}
              max={0.05}
              step={0.001}
              onChange={handleChangeMergeDuration}
            />
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

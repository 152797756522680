import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ApiError } from '@core/types/api';
import { QueryOptions } from '@core/types/react-query';
import { getTestQuestionList } from '../api';
import { META_KEY, QUESTION_KEY } from '../key';
import { TestQuestions, TestQuestionRequest } from '../types';

export const useTestQuestionList = (
  params: TestQuestionRequest,
  options?: QueryOptions<TestQuestions, AxiosError<ApiError>>,
) => {
  return useQuery<TestQuestions, AxiosError<ApiError>, TestQuestions>({
    ...options,
    queryKey: [META_KEY, QUESTION_KEY, params],
    queryFn: () => getTestQuestionList(params),
  });
};

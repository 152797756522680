import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';

const Wrap = styled.div`
  background-color: ${({ theme }) => theme.color.grey[100]};
  width: 100%;
  height: calc(100% - 80px);
  padding: 40px 0;
`;

type BlankLayoutProps = PropsWithChildren;
const BlankLayout = ({ children }: BlankLayoutProps) => {
  return <Wrap>{children}</Wrap>;
};
export default BlankLayout;

import styled from '@emotion/styled';

export interface ITimer {
  count?: number;
}

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.grey[900]};
`;
export const Timer = ({ count = 0 }: ITimer) => {
  const hour = parseInt((count / (100 * 60) / 60).toString());
  const minutes = parseInt(((count / (100 * 60)) % 60).toString());
  const seconds = parseInt(((count / 100) % 60).toString());
  const milliseconds = count % 100;

  return (
    <Wrap>
      {hour.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:
      {seconds.toString().padStart(2, '0')}:{milliseconds.toString().padStart(2, '0')}
    </Wrap>
  );
};

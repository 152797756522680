import styled from '@emotion/styled';
import { Button } from '@components/Button';
import CustomTheme from '@src/core/types/theme';
import { TestCategories } from '@modules/meta';
import { useParams } from 'react-router-dom';
import { useTestStatus } from '@src/modules/patientTest';
import { useMemo } from 'react';

const Wrap = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;
const ItemWrap = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  width: 117px;
  color: ${({ theme }) => theme.color.grey[800]};
`;

const ItemButtonWrap = styled.div`
  white-space: nowrap;
  width: calc(100% - 117px);
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const ItemButton = styled(Button, {
  shouldForwardProp: (props) => props !== 'active' && props !== 'complete',
})`
  white-space: nowrap;
  color: ${({
    theme,
    active,
    complete = false,
  }: {
    active: boolean;
    theme?: CustomTheme;
    complete?: boolean;
  }) =>
    active ? theme?.color?.indigo[700] : complete ? '#32b24d' : theme?.color?.grey[700]};
  background-color: ${({
    theme,
    active,
    complete,
  }: {
    active: boolean;
    theme?: CustomTheme;
    complete?: boolean;
  }) => (active ? theme?.color?.indigo[50] : complete ? '#EBFBEE' : '#f8f9fa')};
  :not(:last-of-type) {
    margin-right: 12px;
  }
  &:hover {
    &:hover {
      background-color: ${({ theme, active, complete }) =>
        !active && !complete ? theme?.color?.grey[100] : undefined};
    }
`;
export interface GnbProps {
  categories: TestCategories;
  onChange: (code: string) => void;
  value: string;
  isDetail?: boolean;
}

export const Gnb = ({ categories, isDetail, onChange, value }: GnbProps) => {
  const { patientId, sessionId, testRecordId } = useParams();
  const { data } = useTestStatus(
    {
      patientId: Number(patientId ?? 0),
      sessionId: Number(sessionId ?? 0),
    },
    {
      enabled: !!(patientId && sessionId) && !isDetail,
    },
  );
  const recordStatus = useMemo(
    () =>
      data?.testRecords?.find(
        ({ testRecordId: recordId }) => testRecordId && Number(testRecordId) === recordId,
      ),
    [data, testRecordId],
  );

  return (
    <Wrap>
      {categories?.map(({ categoryCode, categoryName, children }, index) => {
        return (
          <ItemWrap key={index}>
            <Title key={categoryCode}>{categoryName}</Title>
            <ItemButtonWrap>
              {children?.map(({ categoryCode, categoryName }) => {
                const status = recordStatus?.categoryRecords?.find(
                  ({ categoryCode: code }) => code === categoryCode,
                );
                return (
                  <ItemButton
                    key={categoryCode}
                    color="inherit"
                    onClick={() => onChange(categoryCode)}
                    active={value === categoryCode}
                    complete={status && status?.totalCount === status?.measuredCount}
                  >
                    {categoryName}
                  </ItemButton>
                );
              })}
            </ItemButtonWrap>
          </ItemWrap>
        );
      })}
    </Wrap>
  );
};

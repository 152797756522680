import dayjs from 'dayjs';
import { Gnb, GnbProps } from '@components/Test/Gnb';
import { Box } from '@mui/material';
import { Header as PageHeader } from '@components/Layout/PageLayout';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom';
import { usePatient } from '@modules/patient';
import { useTestCategoryList } from '@modules/meta';
import { getFirstCategoryCode } from '@components/Test/utils';
import { useCallback, useEffect, useMemo } from 'react';
import { useSaveTest } from '@core/hooks/useSaveTest';
import { useResetRecoilState } from 'recoil';
import { test } from '@src/recoils/test';

const PatientInfoWrap = styled.div`
  margin-top: -22px;
  display: flex;
  gap: 24px;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.color.grey[900]};
  margin-bottom: 40px;
`;
export interface HeaderProps extends Pick<GnbProps, 'isDetail'> {
  thirdCategoryCode?: string;
  onChange: (code: string) => void;
}

const PatientInfo = () => {
  const { patientId } = useParams();

  const { data: patient } = usePatient(Number(patientId));
  return (
    <PatientInfoWrap>
      <div>{`${patient?.name} (${patient?.sex})`}</div>
      <div>{`${(patient?.dateOfBirth ?? '')?.replaceAll('-', '.')} (${
        dayjs().year() - dayjs(patient?.dateOfBirth).year()
      }세)`}</div>
    </PatientInfoWrap>
  );
};
export const Header = ({ isDetail, thirdCategoryCode = '', onChange }: HeaderProps) => {
  const { patientId, firstCategoryCode, sessionId, testRecordId } = useParams();
  const resetTests = useResetRecoilState(test);
  const { check, moveConfirm, save } = useSaveTest(
    Number(patientId),
    Number(sessionId),
    Number(testRecordId),
  );

  const { data = [] } = useTestCategoryList(
    {
      code: firstCategoryCode ?? '',
      depth: 3,
    },
    {
      enabled: !!firstCategoryCode,
    },
  );
  useEffect(() => {
    if (data?.length) {
      onChange(getFirstCategoryCode(data));
    }
  }, [onChange, data]);

  useEffect(() => {
    return () => {
      resetTests();
    };
  }, []);

  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    if (!isDetail) {
      if (check()) {
        moveConfirm.open({
          cancelButtonProps: {
            onClick: () => {
              navigate(`/patient/${patientId}`);
            },
          },
          okButtonProps: {
            onClick: () => {
              save();
              navigate(`/patient/${patientId}`);
              moveConfirm.close();
            },
          },
        });
        return;
      }
    }

    navigate(`/patient/${patientId}`);
  }, [check, isDetail, moveConfirm, save, patientId, navigate]);

  const category = useMemo(() => data?.at(0), [data]);

  const handleChangeActiveCategoryCode = useCallback(
    (code: string) => {
      if (!isDetail) {
        if (check()) {
          moveConfirm.open({
            cancelButtonProps: {
              onClick: () => {
                onChange(code);
              },
            },
            okButtonProps: {
              onClick: async () => {
                try {
                  await save();
                  onChange(code);
                } catch (e) {
                  console.log(e);
                } finally {
                  moveConfirm.close();
                }
              },
            },
          });
          return;
        }
      }

      onChange(code);
    },
    [moveConfirm, isDetail, onChange, save, check],
  );
  return (
    <>
      <Box
        borderRadius={1}
        px={5}
        py={3}
        sx={{
          background: 'white',
        }}
      >
        <PageHeader onBack={handleBack} title={category?.categoryName ?? ''} />
        <PatientInfo />
        <Gnb
          isDetail={isDetail}
          categories={category?.children ?? []}
          onChange={handleChangeActiveCategoryCode}
          value={thirdCategoryCode}
        />
      </Box>
      {moveConfirm.rendered}
    </>
  );
};

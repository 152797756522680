import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ApiError } from '@core/types/api';
import { QueryOptions } from '@core/types/react-query';
import { getTestStatus } from '../api';
import { TEST_KEY } from '../key';
import { TestSessionCommonParams, TestStatus } from '../types';

export const useTestStatus = (
  params: TestSessionCommonParams,
  options?: QueryOptions<TestStatus, AxiosError<ApiError>>,
) => {
  return useQuery<TestStatus, AxiosError<ApiError>, TestStatus>({
    ...options,
    queryKey: [TEST_KEY, 'status', params],
    queryFn: () => getTestStatus(params),
  });
};

import styled from '@emotion/styled';
import MuiRadio, { RadioProps as MuiRadioProps } from '@mui/material/Radio';

export type RadioProps = MuiRadioProps;

const CustomRadio = styled(MuiRadio)`
  margin-right: 0;
`;
export const Radio = (props: RadioProps) => {
  return <CustomRadio {...props} />;
};

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ApiError } from '@core/types/api';
import { QueryOptions } from '@core/types/react-query';
import { getTestMeasurementList } from '../api';
import { META_KEY, MEASUREMENT_KEY } from '../key';
import { MeasurementInfo, TestMeasurementRequest } from '../types';

export const useTestMeasurementList = (
  params: TestMeasurementRequest,
  options?: QueryOptions<MeasurementInfo[], AxiosError<ApiError>>,
) => {
  return useQuery<MeasurementInfo[], AxiosError<ApiError>, MeasurementInfo[]>({
    ...options,
    queryKey: [META_KEY, MEASUREMENT_KEY, params],
    queryFn: () => getTestMeasurementList(params),
  });
};

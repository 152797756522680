import { MeasurementInfo, QuestionInfo, TestCategories } from '@modules/meta';

import { Test } from './Test';
import { ScoreUnitTypeCode } from '@core/constants/enum';
import styled from '@emotion/styled';
import { TableProps } from '@components/Test/Section/TestTable/Table';

const TestWrap = styled.div`
  :not(:last-of-type) {
    margin-bottom: 80px;
  }
`;
export interface OnePageTableProps extends Pick<TableProps, 'screenShare' | 'isDetail'> {
  questionInfo: QuestionInfo[];
  categories?: TestCategories;
  measurementInfo: MeasurementInfo[];
}

export const OnePageTable = ({
  categories = [],
  questionInfo,
  screenShare,
  measurementInfo,
  isDetail,
}: OnePageTableProps) => {
  const scoreInfoLength = measurementInfo?.reduce((pre, cur) => {
    const pointLength =
      cur?.measurements?.find(({ scoreInfo }) => scoreInfo?.unitType?.code === 'POINT')
        ?.scoreInfo?.scoreDescription?.scores?.length ?? 0;
    if (pointLength > pre) {
      pre = pointLength;
    }
    return pre;
  }, 0);

  return (
    <>
      {categories?.map(({ categoryCode, categoryName }) => {
        const question = questionInfo?.find(
          ({ categoryCode: questionCategoryCode }) =>
            categoryCode === questionCategoryCode,
        );

        const measurements = measurementInfo?.find(
          ({ key }) => key === question?.measurementCode,
        )?.measurements;

        const scoreDescription = measurements?.find(
          ({ scoreInfo }) => scoreInfo?.unitType?.code === ScoreUnitTypeCode.POINT,
        )?.scoreInfo?.scoreDescription?.scores;

        return question ? (
          <TestWrap key={categoryCode}>
            <Test
              categoryName={categoryName}
              tableProps={{
                categoryCode,
                screenShare,
                measurements: measurements,
                questionId: question.questionId,
                isDetail,
                scoreLength: scoreInfoLength,
              }}
              scoreDescription={scoreDescription}
            />
          </TestWrap>
        ) : null;
      })}
    </>
  );
};

import { useMemo } from 'react';
import styled from '@emotion/styled';

import { IconArrowLeft, IconArrowRight } from '@core/icons';
import { TestCategories } from '@modules/meta';

import { Button } from '@components/Button';

const Wrap = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 24px;
`;

export interface FooterProps {
  activeCategoryCode: string;
  categories?: TestCategories;
  onChangeCategory: (categoryCode: string) => void;
}
export const Footer = ({
  activeCategoryCode,
  categories = [],
  onChangeCategory,
}: FooterProps) => {
  const categoryIndex = useMemo(
    () =>
      categories?.findIndex(({ categoryCode }) => categoryCode === activeCategoryCode),
    [activeCategoryCode, categories],
  );
  return (
    <Wrap>
      {categoryIndex > 0 ? (
        <Button
          startIcon={<IconArrowLeft />}
          onClick={() => onChangeCategory(categories[categoryIndex - 1].categoryCode)}
        >
          {categories[categoryIndex - 1].categoryName}
        </Button>
      ) : null}
      {categoryIndex !== categories?.length - 1 ? (
        <Button
          endIcon={<IconArrowRight />}
          onClick={() => onChangeCategory(categories[categoryIndex + 1].categoryCode)}
        >
          {categories[categoryIndex + 1].categoryName}
        </Button>
      ) : null}
    </Wrap>
  );
};
